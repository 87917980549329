import styled from '@emotion/styled';

const CardLable = styled.span({
  display: 'block',
  fontSize: '1.4rem',
  marginRight: '2rem',
  width: '100%',
});

export default CardLable;
