import * as React from 'react';
import CardLabel from './primitives/CardLabel';
import CardValue from './primitives/CardValue';
import FilledButton from 'primitives/Buttons/FilledButton';
import H2 from 'primitives/Typography/Headings/H2';
import HeroContainer from 'components/Recurly/primitives/HeroContainer';
import HeroStyles from 'components/Recurly/primitives/Hero';
import HeroText from 'components/Recurly/primitives/HeroText';
import OutlinedButton from 'primitives/Buttons/OutlinedButton';
import paths from 'router/Routes/paths';
import Recurly, {
  DefaultObj,
  ErrorMessage,
  FormInnerWrap,
  InputField,
  InputRow,
  PaymentWrap,
  RecurlyField,
  RecurlyFormWrap,
  UpdateButtonWrap,
} from 'components/Recurly';
import RecurlyHOC from 'components/Recurly/RecurlyHOC';
import theme from 'styles/themes/default';
import transport from 'api/transport';
import { Component, createRef } from 'react';
import { fetchRecurlyBillingInfo, updateRecurlyBillingInfo } from './services';
import { get } from 'lodash-es';
import { Navigate } from 'state/Routing/types';

export type ConnectedProps = {
  ampUrl: string;
  profileId: number | null;
  recurlyKey: string;
  sessionId: string | null;
  subscription: string;
};

type Props = ConnectedProps & {
  navigate: Navigate;
};

type State = {
  userBillingInfo: DefaultObj | false;
  errors: DefaultObj;
};

const formRef = createRef<HTMLFormElement>();

class RecurlyUpdatePayment extends Component<Props, State> {
  state = {
    errors: {},
    userBillingInfo: {},
  };

  componentDidMount() {
    const { navigate, recurlyKey } = this.props;
    if (!recurlyKey && __CLIENT__) {
      return navigate({ path: '/404/' });
    }

    if (__CLIENT__) {
      window.removeEventListener('resize', this.configureRecurly);
    }

    return this.configureRecurly();
  }

  /* eslint-disable consistent-return */
  async fetchUserInfo(): Promise<void> {
    const { ampUrl, profileId, sessionId } = this.props;
    let billingInfo;

    try {
      billingInfo = await transport(
        fetchRecurlyBillingInfo({ ampUrl, profileId, sessionId }),
      );

      return this.setState({ userBillingInfo: billingInfo.data });
    } catch (e: any) {
      if (e.response.status === 404) {
        window.location.href = paths.recurly.upgrade;
      }
    }
  }

  async configureRecurly() {
    const { recurlyKey } = this.props;
    if (__CLIENT__) {
      await Recurly.load();
      await Recurly.configure({
        recurlyKey,
      });
      this.fetchUserInfo();
    }
  }

  async setToken(token: string) {
    const { ampUrl, profileId, sessionId } = this.props;
    const { errors } = this.state;

    const billingToken = token;
    try {
      await transport(
        updateRecurlyBillingInfo({
          ampUrl,
          billingToken,
          profileId,
          sessionId,
        }),
      );
      window.location.href = '/my/settings';
    } catch (e) {
      const errorMessages = get(e, 'response.data.errors', ['']);
      const errorMessage = errorMessages
        .map(
          (error: { field: string; message: string }) =>
            `${error.field.split('.')[1]} ${error.message}`,
        )
        .join('');

      this.setState({
        errors: {
          ...errors,
          mainMessage: errorMessage,
        },
      });
    }
  }

  cancel = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    window.location.href = paths.profile.settings;
  };

  async generateToken(e: React.FormEvent<EventTarget>) {
    if (__CLIENT__) {
      e.preventDefault();
      const { errors, token }: any = await Recurly.token(formRef.current);

      if (errors) {
        return this.setState({ errors });
      }

      if (token) {
        this.setState({ errors: {} });
        return this.setToken(token);
      }
    }
  }

  render() {
    const { errors, userBillingInfo }: State = this.state;

    return (
      <RecurlyHOC>
        {!__CLIENT__ ? Recurly.helmet() : null}

        <HeroContainer transformValue="-7rem">
          <HeroStyles>
            <HeroText>Update your billing information</HeroText>
          </HeroStyles>
        </HeroContainer>
        <RecurlyFormWrap>
          {userBillingInfo.cardType ?
            <PaymentWrap>
              <H2>Current Card Info</H2>
              <div css={{ display: 'inline-block', marginRight: '1rem' }}>
                <CardLabel>Card Ending in: </CardLabel>
                <CardValue>{userBillingInfo.digitsLastFour}</CardValue>
              </div>
              <div css={{ display: 'inline-block', marginRight: '1rem' }}>
                <CardLabel>Valid till: </CardLabel>
                <CardValue>
                  {userBillingInfo.month}/{userBillingInfo.year}
                </CardValue>
              </div>
            </PaymentWrap>
          : null}
          {errors.mainMessage ?
            <ErrorMessage data-test="error-message">
              {errors.mainMessage}
            </ErrorMessage>
          : null}
          <form
            data-test="checkoutForm"
            onSubmit={e => this.generateToken(e)}
            ref={formRef}
          >
            <H2>Update your card using the form below</H2>
            <FormInnerWrap>
              <InputRow>
                <div>
                  <label>First Name</label>
                  <InputField
                    data-recurly="first_name"
                    placeholder="First Name"
                    type="text"
                  />
                </div>
                <div>
                  <label>Last Name</label>
                  <InputField
                    data-recurly="last_name"
                    last
                    placeholder="Last Name"
                    type="text"
                  />
                  <InputField
                    data-recurly="country"
                    placeholder="Last Name"
                    type="hidden"
                    value="US"
                  />
                </div>
              </InputRow>
              <InputRow full>
                <div>
                  <label>Card Details</label>
                  <RecurlyField
                    className={
                      (
                        errors.number ||
                        errors.cvv ||
                        errors.month ||
                        errors.year
                      ) ?
                        'error'
                      : ''
                    }
                    data-recurly="card"
                  />
                </div>
              </InputRow>
              <InputRow>
                <div>
                  <label>Zip / Postal Code</label>
                  <InputField
                    className={errors.postal_code ? 'error' : ''}
                    data-recurly="postal_code"
                    data-test="recurly-postal"
                    placeholder="Enter Here"
                    type="text"
                  />
                </div>
              </InputRow>
            </FormInnerWrap>
            <UpdateButtonWrap>
              <FilledButton
                customStyles={{
                  backgroundColor: `${theme.colors.green['600']}`,
                  hoverColor: '',
                }}
                data-test="checkout-button"
                isBlock
                styleType="cta"
                tabIndex={3}
                type="submit"
              >
                Update & Save
              </FilledButton>
              <OutlinedButton
                onClick={(e: React.SyntheticEvent) => this.cancel(e)}
                tabIndex={3}
              >
                Cancel
              </OutlinedButton>
            </UpdateButtonWrap>
          </form>
        </RecurlyFormWrap>
      </RecurlyHOC>
    );
  }
}

export default RecurlyUpdatePayment;
