import composeRequest, {
  authHeaders,
  body,
  header,
  method,
  urlTagged,
} from 'api/helpers';

export function fetchRecurlyBillingInfo({ ampUrl, profileId, sessionId }) {
  return composeRequest(
    authHeaders(profileId, sessionId),
    urlTagged`${{ ampUrl }}/api/v3/subscription/external/recurly/billingInfo`,
    method('get'),
    header('Content-Type', 'application/json'),
    header('Accept', 'application/json; charset=utf-8'),
  )();
}

export function updateRecurlyBillingInfo({
  ampUrl,
  billingToken,
  profileId,
  sessionId,
}) {
  return composeRequest(
    authHeaders(profileId, sessionId),
    urlTagged`${{ ampUrl }}/api/v3/subscription/external/recurly/billingInfo`,
    method('PUT'),
    body({
      billingToken,
    }),
    header('Content-Type', 'application/json'),
    header('Accept', 'application/json; charset=utf-8'),
  )();
}
